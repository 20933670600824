/* ----- website css ----- */
/* @font-face {
     font-family: 'Gilroy';
     src: url('../fonts/Gilroy-Black.ttf') format('ttf');
     font-weight: 900;
     font-style: normal;
     font-display: swap;
}
 */
 @font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy-Medium.woff2') format('woff2'), url('../fonts/Gilroy-Medium.woff') format('woff'), url('../fonts/Gilroy-Medium.ttf') format('ttf');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy-Light.woff2') format('woff2'), url('../fonts/Gilroy-Light.ttf') format('ttf'), url('../fonts/Gilroy-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy-Regular.woff2') format('woff2'), url('../fonts/Gilroy-Regular.ttf') format('ttf'), url('../fonts/Gilroy-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy-Heavy.woff2') format('woff2'), url('../fonts/Gilroy-Heavy.ttf') format('ttf'), url('../fonts/Gilroy-Heavy.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy-Bold.woff2') format('woff2'), url('../fonts/Gilroy-Bold.ttf') format('ttf'), url('../fonts/Gilroy-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
body {
    font-family: 'Gilroy';
    background: url(../images/homepage/bg-layer.png) no-repeat;
    background-size: cover;
    background-position: center center;
    color: #6a6a6a;
    font-weight: 500;
}
a {
    text-decoration: none;
}
img {
    width: 100%;
}
h1.hdng-h1 {
    color: #FFF;
    font-size: 48px;
    font-weight: 600;
    line-height: 64px;
}
.about-sec-txt h2.hdng-h2 {
    text-align: left;
}
.services-banner h2.hdng-h2 {
    text-align: center !important;
}
h2.hdng-h2 {
    margin-bottom: 0;
    color: #3A6AB9;
    font-size: 44px;
    font-weight: 600;
    line-height: normal;
}
.product-box-data p {
    min-height: 130px;
}
h6.hdng-h6 {
    color: #000;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 0px;
    text-align: left;
}
h4.hdng-h4 {
    font-size: 30px;
    font-weight: 600;
    color: #000;
    margin-bottom: 0px;
}
p.para-16 {
    color: #646464;
    font-size: 16px;
}
p.para-18 {
    color: #000;
    font-size: 18px;
    font-weight: 500;
}
.custom-btn:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 0;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: #3A6AB9;
    transition: all 0.3s ease;
}
.custom-btn:hover {
    color: #fff;
    border: 1px solid #3A6AB9;
}
.custom-btn:hover:after, .custom-btn2:hover:after {
    top: auto;
    bottom: 0;
    height: 100%;
}
.custom-btn {
    color: #000;
    font-weight: 700;
    font-size: 18px;
    transition: all 0.3s ease;
    position: relative;
    display: inline-block;
    background: #fff;
    padding: 12px 30px;
    text-decoration: none;
    border: 1px solid #fff;
    border-radius: 3px;
    overflow: hidden;
}
.custom-btn2 {
    color: #3A6AB9;
    font-weight: 700;
    font-size: 18px;
    transition: all 0.3s ease;
    position: relative;
    display: inline-block;
    background: transparent;
    padding: 12px 30px;
    text-decoration: none;
    border: 2px solid #3A6AB9;
    border-radius: 3px;
    overflow: hidden;
}
.custom-btn2:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 0;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: #3A6AB9;
    transition: all 0.3s ease;
}
.custom-btn2:hover {
    color: #fff;
}
.custom-btn span, .custom-btn2 span {
    position: relative;
    z-index: 9;
}
.listing li {
    list-style: none;
}
.listing {
    padding: 0;
    margin: 0;
}
.hdng {
    margin-bottom: 30px;
    text-align: center;
}
.slick-arrow:before {
    color: #3A6AB9 !important;
    opacity: 1 !important;
}
.slick-arrow:hover:before {
    color: #fff !important;
}
section.about-sec .row {
    align-items: center;
}
.about-sec-txt p strong {
    color: #000;
}
.about-sec-txt p {
    color: #646464;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}
.about-sec-txt {
    padding: 0 0 0 35px;
}
/* ----- header css ----- */
.site-header {
    position: absolute;
    width: 100%;
}
.mobile h4 {
    color: #fff;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 600;
}
.mobile h4 span {
    display: block;
    font-size: 12px;
    font-weight: 700;
}
.site-header .navbar-expand-lg .navbar-collapse {
    justify-content: center;
}
.site-header .navbar-expand-lg .navbar-nav .nav-item a {
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    padding: 0;
}
.site-header .navbar-expand-lg .navbar-nav .nav-item {
    padding: 0 20px;
}
.site-header .navbar.navbar-expand-lg {
    padding: 20px 0;
}
.banner-data h1 {
    margin: 0;
}
.banner-data h1 span {
    font-weight: 900;
}
.banner-data p {
    font-size: 20px;
    color: #fff;
    font-weight: 500;
    margin: 30px 0;
}
.banner-data {
    max-width: 615px;
}
/* ----- footer css ----- */
.site-footer {
    background: url(../images/footer/footer-banner.jpg) no-repeat;
    background-position: center center;
    background-size: cover;
    padding: 50px 0;
}
.site-footer h4 {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 20px;
    color: #000;
}
.footer-links .listing li a {
    color: #646464;
    font-weight: 500;
}
.footer-links .listing li {
    padding-bottom: 10px;
}
.footer-links .listing li a:hover {
    color: #3A6AB9;
}
.bottom-links .listing {
    display: flex;
    justify-content: flex-end;
    gap: 15px;
}
.bottom-links .listing li a {
    color: #6a6a6a;
    font-weight: 500;
}
.bottom-links .listing li a:hover {
    color: #3A6AB9;
}
.site-footer .footer-data h3 {
    margin: 20px 0;
    font-size: 36px;
    font-weight: 700;
    color: #000;
}
.site-footer .footer-data .listing.social {
    display: flex;
    gap: 15px;
    margin-top: 30px;
}
.bottom-footer {
    margin-top: 60px;
}
.bottom-footer .copyright p {
    margin: 0;
}
.site-footer .row .col:first-child {
    flex: 0 0 30%;
}
.addres-box h6 {
    color: #000;
    font-weight: 700;
    text-transform: uppercase;
}
.contact-details {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
}
.contact-details p {
    margin: 0;
    font-size: 18px;
    color: #3A6AB9;
    font-weight: 600;
}
.site-footer .row .col:last-child {
    flex: 0 0 25%;
}
.site-footer img {
    width: auto;
}
/* ----- homepage css ----- */
.banner {
    background: url(../images/homepage/banner.jpg) no-repeat;
    background-position: center center;
    background-size: cover;
    height: 100vh;
    display: flex;
    align-items: center;
}
.about-sec-txt ul li {
    margin-bottom: 10px;
}
.about-sec-txt ul {
    padding-left: 20px;
    margin: 0;
}
.about-sec-txt h4 {
    margin-bottom: 10px;
}
.about-sec-txt h5 {
    color: #3A6AB9;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 18px;
}
.about-sec-img img {
    border-radius: 5px;
}
.counter-sec {
    background: linear-gradient(71deg, #0CF 0%, #006 102.45%);
    padding: 60px 0;
}
.counter-sec-data {
    text-align: center;
}
.counter-sec-data img {
    width: auto;
}
.counter-sec-data h4 {
    color: #fff;
}
.counter-sec-data h2.hdng-h2 {
    color: #fff;
    margin-top: 30px;
    margin-bottom: 10px;
}
.counter-sec-data p {
    font-size: 20px;
    color: #fff;
    text-transform: uppercase;
}
.counter-sec .row .col-md-4:nth-child(2) .counter-sec-data {
    border-left: 2px dashed rgba(255, 255, 255, 0.23);
    border-right: 2px dashed rgba(255, 255, 255, 0.23);
}
.technology-sec-data img:nth-child(n - 11) {
    width: 70%;
}
.technology-sec-data {
    padding: 30px 20px;
    border-radius: 11px;
    color: #000;
    margin-bottom: 25px;
    min-height: 165px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    background: #fff !important;
    box-shadow: 0px 8px 24px 0px rgba(149, 157, 165, 0.20);
}
.technology-sec-data img {
    width: auto;
}
.technology-sec-data p {
    margin: 20px 0 0;
    font-weight: 600;
}
.techno h2.hdng-h2 {
    font-size: 38px;
}
.techno {
    background: #fff;
    border-radius: 7px;
    box-shadow: 0 12px 24px 0 hsl(0deg 0% 0% / 5%);
    padding: 60px 0 140px;
}
.technology-sec {
    margin: 0 7%;
}
.testimonial-box-avtar img {
    width: auto;
}
.testimonial-box-data {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 30px;
    height: 100px;
}
.testimonial-box-data .testimonial-box-avtar h6 {
    margin: 0;
    font-size: 24px;
    font-weight: 700;
    color: #000;
}
.testimonial-box-data .testimonial-box-avtar p {
    margin: 0;
}
.testimonial-box-content {
    margin-right: 40px;
}
.testimonials ul.slick-dots {
    bottom: -75px;
    text-align: right;
    display: inline-block !important;
    width: auto;
    right: 0;
}
.testimonials ul.slick-dots li {
    width: auto;
    height: auto;
    margin: 0 5px;
}
.testimonials ul.slick-dots li button {
    width: 15px;
    height: 15px;
    padding: 0;
    background: #DFEBFF;
    border-radius: 50%;
    outline: 2px solid transparent;
    outline-offset: 3px;
}
.testimonials ul.slick-dots li button:before {
    display: none;
}
.testimonials ul.slick-dots li.slick-active button {
    background: #3A6AB9;
    outline: 2px solid #3A6AB9;
}
.testimonial-sec .row {
    background: #F2F8FF;
    border-radius: 11px;
    overflow: hidden;
    align-items: center;
    position: relative;
}
.testimonial-sec {
    padding: 70px 0;
}
.testimonial-sec .col-md-6:first-child {
    background: #3A6AB9;
}
.testimonial-sec .hdng {
    margin: 0;
    padding: 45px 30px;
    text-align: left;
}
.testimonial-sec .hdng h2 {
    color: #fff;
    margin-bottom: 10px;
}
.testimonials {
    padding: 0 30px;
}
.testimonial-sec .hdng p {
    color: #fff;
    margin-bottom: 30px;
}
.testimonial-sec .hdng img {
    border-radius: 7px;
}
.testimonials .slick-slider button.slick-arrow {
    width: 55px;
    height: 55px;
    border-radius: 3px;
    background: #fff;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.10);
    bottom: -120px;
    top: auto;
    left: 0;
}
.testimonials .slick-slider button.slick-arrow.slick-next {
    left: 70px;
}
.testimonials .slick-slider button.slick-arrow:hover {
    background: #3a6ab9;
}
.testimonials .quote {
    position: absolute;
    width: auto;
    right: 50px;
    top: -18%;
}
.service-box {
    border-radius: 7px;
    background: #fff;
    box-shadow: 0 12px 24px 0 hsl(0deg 0% 0% / 5%);
    padding: 5px;
    margin-bottom: 25px;
}
.service-box .service-box-txt {
    padding: 10px;
}
.service-box .service-box-txt h4 {
    font-size: 20px;
    color: #000;
    font-weight: 600;
    margin-bottom: 10px;
}
.service-box .service-box-txt p {
    margin-bottom: 10px;
    min-height: 155px;
}
section.product-sec h2.hdng-h2 {
    text-align: center;
}
section.product-sec h6.hdng-h6 {
    text-align: center;
}
.service-box .service-box-txt a {
    color: #3A6AB9;
    font-weight: 700;
}
.service-box img {
    border-radius: 5px 5px 0 0;
}
.service-sec {
    padding-bottom: 70px;
}
.success-sec {
    padding: 70px 0;
}
.success-sec-data {
    text-align: center;
}
.success-sec-data .success-sec-img {
    width: 180px;
    height: 180px;
    background: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    filter: drop-shadow(0px 4.685184955596924px 7.96481466293335px rgba(0, 0, 0, 0.02)) drop-shadow(0px 20.61481475830078px 16.491849899291992px rgba(0, 0, 0, 0.03)) drop-shadow(0px 50.599998474121094px 32.88999938964844px rgba(0, 0, 0, 0.04));
    margin: 0 auto 40px;
}
.success-sec-data .success-sec-img img {
    width: auto;
}
.product-inner {
    background: #fff;
    border-radius: 7px;
    box-shadow: 0 12px 24px 0 hsl(0deg 0% 0% / 5%);
    padding: 60px 0;
}
.product-sec {
    margin: 0 7%;
}
.product-box .product-box-img img {
    width: auto;
}
.product-box .product-box-data {
    padding: 0px 10px;
}
.product-box .product-box-data h2.hdng-h2 {
    font-size: 18px;
}
.product-box .product-box-data h4 {
    font-size: 18px;
    color: #000;
    font-weight: 600;
    margin-bottom: 12px;
}
.product-box .product-box-data p {
    margin: 0;
}
.product-box .product-box-link {
    border-top: 1px solid #F2F8FF;
    padding: 15px 10px;
}
.product-box .product-box-link a {
    color: #3A6AB9;
    font-weight: 700;
}
.blog-sec {
    padding: 70px 0;
}
.blog-sec .hdng {
    max-width: 1000px;
    margin: 0 auto 50px;
}
.blog-box-img {
    border-radius: 7px;
    background: #FFF;
    box-shadow: 0px 8px 24px 0px rgba(149, 157, 165, 0.20);
    padding: 10px;
}
.blog-box-txt {
    margin-top: 20px;
}
.blog-box-txt h4 {
    font-size: 18px;
    color: #000;
    font-weight: 600;
    margin-bottom: 10px;
}
.blog-box-txt a {
    font-weight: 700;
    color: #3a6ab9;
}
.blog-btn {
    text-align: center;
    margin-top: 50px;
}
.contact-op > br {
    display: none;
}
header.site-header.sticky {
    position: fixed;
    background: hsl(0deg 0% 0% / 90%);
}
header.site-header.sticky .navbar.navbar-expand-lg {
    padding: 5px 0 ;
}
.images-featured img {
    width: 100%;
}
.Featured-in-code {
    width: 33%;
}
.logos-clients {
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: 14%;
    gap: 65px;
}
.Featured-in-code h4 {
    color: #FFF;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    position: relative;
}
.Featured-in-code h4:after {
    position: absolute;
    content: '';
    background: #fff;
    width: 26%;
    height: 3px;
    left: 0;
    bottom: -4px;
}
.inner-section .col-md-6:first-child {
    padding: 0;
}
.inner-section .container {
    padding: 0;
}
.about-sec{
    margin: 0 7%;
}
.about-sec .inner-section {
    background: #fff;
    border-radius: 7px;
    box-shadow: 0 12px 24px 0 hsl(0deg 0% 0% / 5%);
    padding: 33px 0px 60px;
    margin-top: -6%;
}
.our-story-sec {
    background: #F2F8FF;
    padding: 56px 0;
}
p.all-pargraph-design {
    color: #646464;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}
.our-story-content h2 {
    margin-bottom: 15px;
}
.classicl-dotts {
    background: transparent;
    padding: 0 30px 20px;
    text-align: center;
}
.product-inners {
    padding: 82px 0;
}
.classicl-dotts p.all-pargraph-design {
    padding-top: 10px;
}
.row.loop-info-code {
    align-items: center;
}
h4.classic-gold {
    color: #1F1E1E;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
h5.complete-date-management {
    color: #1F1E1E;
    font-size: 16px;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
}
.loop-content-opt {
    padding-left: 60px;
}
.row.boxing-tebs {
    margin-top: 45px;
}
.product-box-data a {
    color: #3A6AB9;
    font-weight: 700;
}
.product-box {
    margin-bottom: 25px;
    padding: 5px 13px 11px;
    border-radius: 5px;
    background: #FFF;
    box-shadow: 0px 8px 24px 0px rgba(149, 157, 165, 0.20);
    min-height: 270px;
}
section.service-sec h2.hdng-h2 {
    text-align: center;
}
section.service-sec h6.hdng-h6 {
    text-align: center;
    margin-top: 7px;
    margin-bottom: 47px;
}
.techno h2.hdng-h2 {
    text-align: center;
}
section.technology-sec p.para-16 {
    text-align: center;
    width: 89%;
    margin: 13px auto 40px;
}
.one-box-sec {
    background: #F0F5FF;
    filter: drop-shadow(2px 6px 23px rgba(43, 45, 66, 0.17));
    backdrop-filter: blur(13px);
    width: 330px;
    padding: 11px;
    position: absolute;
    top: 0;
}
.month-code {
    background: #3A6AB9;
    border-radius: 9px 9px 0 0;
}
.month-code h4 {
    color: #FFF;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    padding: 5px 0;
    margin: 0;
}
.listing-journey {
    background: #fff;
    padding: 15px 28px 24px;
    border-radius: 0 0 9px 9px;
    margin: 0;
    text-align: left;
}
.listing-journey li {
    color: #3A6AB9;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    padding: 5px 0;
}
.bar-journey {
    text-align: center;
    position: relative;
}
.bar-journey img {
    width: auto;
}
.one-box-sec.jorney-1 {
    right: 3%;
    top: 8%;
}
.one-box-sec.jorney-2 {
    top: 20%;
    left: 3%;
}
.one-box-sec.jorney-3 {
    right: 3%;
    top: 35%;
}
.one-box-sec.jorney-4 {
    top: 48%;
    left: 3%;
}
.one-box-sec.jorney-5 {
    right: 3%;
    top: auto;
    bottom: 15%;
}
.one-box-sec.jorney-6 {
    bottom: 8%;
    left: 3%;
    top: auto;
}
/*-----Service Page-----*/
.services-banner .services-banner-data {
    background: #ffff;
    border-radius: 7px;
    box-shadow: 0 12px 24px 0 hsl(0deg 0% 0% / 8%);
    padding: 50px;
    text-align: center;
    margin-top: -150px;
    position: relative;
}
.service-slider {
    padding: 70px 0;
}
.service-slider .hdng {
    margin-bottom: 25px;
}
.services-banner .services-banner-data p {
    font-size: 20px;
    font-weight: 400;
    margin-top: 20px;
}
.service-slider ul.slick-dots li {
    width: auto;
    height: auto;
    margin: 0 5px;
}
.service-slider ul.slick-dots li button {
    width: 15px;
    height: 15px;
    padding: 0;
    background: #DFEBFF;
    border-radius: 50%;
    outline: 2px solid transparent;
    outline-offset: 3px;
}
.service-slider ul.slick-dots li button:before {
    display: none;
}
.service-slider ul.slick-dots {
    bottom: -30px;
    text-align: center;
    display: inline-block !important;
    width: auto;
    left: 0;
    right: 0;
    z-index: 1;
}
.service-slider ul.slick-dots li.slick-active button {
    background: #3A6AB9;
    outline: 2px solid #3A6AB9;
}
.service-slider .slick-slider button.slick-arrow {
    width: 55px;
    height: 55px;
    border-radius: 3px;
    background: #fff;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.10);
    bottom: -80px;
    top: auto;
    left: 20px;
    z-index: 2;
}
.service-slider .slick-slider button.slick-arrow.slick-next {
    right: 20px;
    left: auto;
}
.slider-services {
    padding: 7.5px;
}
.service-slider .slick-slider button.slick-arrow:hover {
    background: #3a6ab9;
}
li.cloud-list-close {
    list-style: none;
}
ul.listing-journey.list-close-value {
    padding-left: 15px;
}
li.cloud-list-close img {
    padding-right: 10PX;
}
.jorney-7 {
    bottom: -4%;
    left: 34%;
    top: auto;
    width: 50%;
}
.jorney-7 ul {
    text-align: center;
    padding: 6px;
}
.jorney-7 ul li {
    color: #3A6AB9;
    text-align: center;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    list-style: none;
}
.form-design-code label {
    display: block;
    margin-bottom: 8px;
}
.form-design-code input, .form-design-code textarea {
    width: 100%;
    padding: 8px;
    margin-bottom: 16px;
    box-sizing: border-box;
}
.form-design-code textarea {
    resize: vertical;
    height: 130px;
}
.ReactModal__Overlay.ReactModal__Overlay--after-open {
    z-index: 9999;
}
.ReactModal__Content.ReactModal__Content--after-open {
    width: 33%;
    height: auto !important;
    z-index: 99999 !important;
    margin: 0 auto;
}
.light-code h3 {
    color: #fff;
    text-align: center;
    font-size: 35px;
    position: relative;
    z-index: 999;
}
.light-code:after {
    position: absolute;
    content: '';
    background-color: #00000070;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 9px;
}
.ReactModal__Overlay.ReactModal__Overlay--after-open {
    background-color: rgb(0 0 0 / 75%) !important;
}
.light-code {
    background: url(../images/homepage/banner.jpg);
    padding: 14% 0;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    border-radius: 9px;
    position: relative;
}
.form-design-code form {
    max-width: 95%;
    margin: 20px auto;
}
button.closed-butt {
    position: absolute;
    right: -16px;
    top: -16px;
    z-index: 9999;
    background: #fff;
    transform: rotate(45deg);
    border: none;
    font-size: 49px;
    border-radius: 50%;
    width: 60px;
    height: 60px;
}
.bottom-links ul.listing li button {
    background: transparent;
    border: none;
    font-size: 14px;
}
body .slick-prev:before {
    content: '';
    position: absolute;
    background: url(../images/homepage/arrow.png);
}
body .slick-next:before {
    content: '';
    position: absolute;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAOCAYAAAASVl2WAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACLSURBVHgBfZHRDYQgDEDtAf83giNcmOCcRD4YwJFwEnUBZnAEB4Bgq2IMapuUNLwXaFOoHsIYUyulhpSSAwbWmDNwMMbYAAedc/sLb5AYcJDikyHWSwk3AWF/1F8pZVtOJbz3o9aaevlTYl3h3XQKdHCSyOabJK7/PUmibOoq4QC/2y5yWGu7EMK4AnQfgFXkD0T4AAAAAElFTkSuQmCC);
    width: 50px;
    height: 50px;
    background-repeat: no-repeat;
    left: -17px;
    right: 0;
    top: -16px;
    bottom: 0;
    transform: rotate(180deg);
}
body .slick-prev:before {
    content: '';
    position: absolute;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAOCAYAAAASVl2WAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACLSURBVHgBfZHRDYQgDEDtAf83giNcmOCcRD4YwJFwEnUBZnAEB4Bgq2IMapuUNLwXaFOoHsIYUyulhpSSAwbWmDNwMMbYAAedc/sLb5AYcJDikyHWSwk3AWF/1F8pZVtOJbz3o9aaevlTYl3h3XQKdHCSyOabJK7/PUmibOoq4QC/2y5yWGu7EMK4AnQfgFXkD0T4AAAAAElFTkSuQmCC);
    width: 50px;
    height: 50px;
    background-repeat: no-repeat;
    left: 42%;
    right: 0;
    top: 20px;
    bottom: 0;
}
.slick-arrow.slick-next:hover:before {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAOCAYAAAASVl2WAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABpSURBVHgBxZDBCYAwDEV/qwM4giO4kSu4ga7iRI6gG5SqUD19U+lFaa76IRDyHoEE28l+PTi7wBq5eIEiUJXi8GfJ5KTCYJK2klrsWygt2gRBYnzA9JO4nj5w+ALGCHAqvM/a2cjtHZRcR9iyrJ3KeIQAAAAASUVORK5CYII=);
    width: 50px;
    height: 50px;
    background-repeat: no-repeat;
    right: 0;
    bottom: 0;
    transform: none;
    content: '';
    z-index: 99;
    top: 20px;
    left: 23px;
}
.slick-arrow:hover:before {
    background: url(../images/homepage/hover.png);
    background-repeat: no-repeat;
    transform: rotate(180deg);
    width: 50px;
    height: 50px;
    background-repeat: no-repeat;
    left: -17px;
    right: 0;
    top: -16px;
    bottom: 0;
    transform: rotate(180deg);
}
.contact-details p a {
    margin: 0;
    font-size: 18px;
    color: #3A6AB9;
    font-weight: 600;
}
.jorney-7 ul {
    border-radius: 9px;
}
.test-client-img {
    position: relative;
    right: 58px;
    top: 11px;
}
.testimonial-box-avtar.test-pergraph {
    position: relative;
    right: 115px;
}
.testimonial-box {
    overflow: hidden;
    width: 100% !important;
    padding: 0 0 0 20px;
}
.closed-blog {
    margin-bottom: 10%;
}
p.cost-side {
    width: 80%;
    margin: 0 auto;
    color: #646464;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-top: 10px;
}
.blog-breadcrumb-colum {
    background: url(../images/services/blog-detail/generative.jpg) no-repeat;
    min-height: 450px;
    position: relative;
    top: -71px;
}
header.site-header {
    z-index: 999;
}
.detail-blog-design.detail-ukk {
    margin-top: 20px;
}
section#partner .techno {
    padding-bottom: 70px;
}
.detail-blog-design p strong {
    font-size: 20px;
    color: #000;
    margin: 0 !important;
}
.detail-blog-design p {
    margin: 3px 0;
    color: #646464;
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
}
.detail-blog-design {
    margin-top: 10px;
}
.cloud-end {
    margin-bottom: 80px;
}
.Comming-content img {
    width: auto;
}
section.comming-page {
    background: url(../images/services/services-banners/Hero-Banner.webp) no-repeat;
    background-size: cover;
    height: 100vh;
    position: relative;
    top: 0;
    display: table-cell;
    width: 100vw;
    vertical-align: middle;
}
p.coming-description {
    font-size: 22px;
    letter-spacing: 1px;
}
.Comming-content {
    color: #fff;
    left: 0;
    position: relative;
    text-align: center;
    width: 100%;
    z-index: 999;
}
section.comming-page:after {
    content: '';
    position: absolute;
    top: 0;
    background: #000;
    width: 100%;
    height: 100%;
    opacity: 0.5;
}
.Comming-content h4 {
    color: #fff;
    font-size: 72px;
    text-align: center;
    font-weight: bold;
    margin: 25px 0 15px;
}
.heading-proform h3.helping-head {
    color: #3A6AB9;
    font-size: 42px;
    font-weight: bold;
    line-height: normal;
}
.heading-proform {
    margin-top: 52px;
}
.heading-proform p {
    color: #646464;
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
}
.scroll-top-design {
    position: fixed;
    bottom: 100px;
    right: 0;
    width: 7%;
}
.scroll-top-design button.scroll-to-top {
    width: 60px;
    background: #000;
    border: navajowhite;
    padding: 0 19px;
    border-radius: 50px;
    box-shadow: 0 0 3px #fff;
    height: 60px;
    line-height: 0;
    opacity: 0;
}
.scroll-top-design button.scroll-to-top.visible {
    opacity: 1;
}
.scroll-top-design button.scroll-to-top:hover {
    background: #3a6ab9;
    transition: 1s;
}
/* @-webkit-keyframes scroll {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
   }
    100% {
        -webkit-transform: translateX(calc(-250px * 7));
        transform: translateX(calc(-250px * 7));
   }
}
@keyframes scroll {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
   }
    100% {
        -webkit-transform: translateX(calc(-250px * 7));
        transform: translateX(calc(-250px * 7));
   }
}
*/
.slider::before, .slider::after {
    background: linear-gradient(to right, white 0%, rgba(255, 255, 255, 0) 100%);
    content: "";
    height: 100px;
    position: absolute;
    width: 200px;
    z-index: 2;
}
.slider::after {
    right: 0;
    top: 0;
    -webkit-transform: rotateZ(180deg);
    transform: rotateZ(180deg);
}
.slider-text::before {
    left: 0;
    top: 0;
}
.slider-text .slide-track {
    -webkit-animation: scroll 40s linear infinite;
    animation: scroll 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
}
.slider .slide {
    height: 100px;
    width: 250px;
}
.slide {
    width: 7%;
    text-align: center;
    border: 1px solid #fff;
    padding: 12px 0px;
}
.slide h3 {
    color: #fff;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1px;
    font-size: 20px;
    line-height: 34px;
}
.slider-text {
    background: linear-gradient(71deg, #0CF 0%, #006 102.45%);
    padding: 4px;
}
.banner-data-footer .col-md-4 {
    padding: 0;
}
.banner-data-footer .col-md-8 {
    padding: 0 6px;
}
.banner-data-footer div {
    text-align: right;
    color: #4673bd;
    font-size: 25px;
}
.banner-data-footer div {
    text-align: right;
    color: #4673bd;
    font-size: 25px;
}
.banner-data-footer .slick-vertical .slick-slide {
    position: relative;
    top: -20px;
}
.banner-data-footer .col-md-8 h2 {
    color: #000;
    font-size: 22px;
    font-weight: 600;
    text-align: left;
}
body.Coming .site-header, body.Coming .site-footer {
    display: none;
}
a.custom-btn-design {
    border: 1px solid #fff;
    padding: 15px 0;
    color: #fff;
    display: block;
    width: 10%;
    margin: 30px auto 0;
    border-radius: 5px;
}
